import * as PRODUCT_ACTION_TYPES from "./types";

export const productsFetchRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST,
});

export const productsFetchRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST_SUCCESS,
  data,
});

export const productsFetchRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST_FAILURE,
  error,
});

export const productsListCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_LIST_CLEAN_PROPS,
});

export const productsDetailFetchRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST,
});

export const productsDetailFetchRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST_SUCCESS,
  data,
});

export const productsDetailFetchRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST_FAILURE,
  error,
});

export const productsImagesFetchRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST,
});

export const productsImagesFetchRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST_SUCCESS,
  data,
});

export const productsImagesFetchRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST_FAILURE,
  error,
});

export const productsDetailCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_DETAIL_CLEAN_PROPS,
});

export const productUpsertRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST,
});

export const productUpsertRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST_SUCCESS,
  data,
});

export const productUpsertRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST_FAILURE,
  error,
});

export const productsFeaturesFetchRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST,
});

export const productsFeaturesFetchRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST_SUCCESS,
  data,
});

export const productsFeaturesFetchRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST_FAILURE,
  error,
});

export const productsVariantFetchRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST,
});

export const productsVariantFetchRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST_SUCCESS,
  data,
});

export const productsVariantFetchRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST_FAILURE,
  error,
});

export const productFeatureCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_FEATURE_CLEAN_PROPS,
});

export const productVariantCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_VARIANT_CLEAN_PROPS,
});

export const productImageCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_IMAGES_CLEAN_PROPS,
});

export const productUpsertCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_UPSERT_CLEAN_PROPS,
});

export const productsDropdownRequest = () => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST,
});

export const productsDropdownRequestSuccess = (data) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST_SUCCESS,
  data,
});

export const productsDropdownRequestFailure = (error) => ({
  type: PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST_FAILURE,
  error,
});

export const productsDropdownCleanRequest = () => ({
  type: PRODUCT_ACTION_TYPES.PRODUCT_DROPDOWN_CLEAN_PROPS,
});
