import * as SETTING_ACTION_TYPES from "./types";

export const passwordChangeRequest = () => ({
  type: SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST,
});

export const passwordChangeRequestSuccess = (data) => ({
  type: SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST_SUCCESS,
  data,
});

export const passwordChangeRequestFailure = (error) => ({
  type: SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST_FAILURE,
  error,
});
