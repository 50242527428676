import {
  PRODUCT_ADD_API,
  PRODUCT_DETAIL_FETCH_API,
  PRODUCT_DROPDOWN_API,
  PRODUCT_FEATURE_ADD_API,
  PRODUCT_FETCH_API,
  PRODUCT_IMAGE_ADD_API,
  PRODUCT_VARIANT_ADD_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as PRODUCT_ACTIONS from "./actions";

export const fetchProductLists = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsFetchRequest());

    return api
      .storeAPI(PRODUCT_FETCH_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchProductDetailByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsDetailFetchRequest());

    return api
      .fetchAPI(`${PRODUCT_DETAIL_FETCH_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsDetailFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertProduct = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${PRODUCT_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${PRODUCT_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${PRODUCT_ADD_API}/${formData}`);
    }
  };

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const uploadProductImage = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productUpsertRequest());

    return api
      .storeAPI(`${PRODUCT_IMAGE_ADD_API}`, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const deleteProductImage = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productUpsertRequest());

    return api
      .deleteAPI(`${PRODUCT_IMAGE_ADD_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchProductImagesByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsImagesFetchRequest());

    return api
      .fetchAPI(`${PRODUCT_IMAGE_ADD_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsImagesFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsImagesFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertProductFeature = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${PRODUCT_FEATURE_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${PRODUCT_FEATURE_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${PRODUCT_FEATURE_ADD_API}/${formData}`);
    }
  };

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchProductFeature = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsFeaturesFetchRequest());

    return api
      .fetchAPI(`${PRODUCT_FEATURE_ADD_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsFeaturesFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsFeaturesFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertProductVariant = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${PRODUCT_VARIANT_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${PRODUCT_VARIANT_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${PRODUCT_VARIANT_ADD_API}/${formData}`);
    }
  };
  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchProductVariantByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsVariantFetchRequest());

    return api
      .fetchAPI(`${PRODUCT_VARIANT_ADD_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsVariantFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsVariantFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchProductDropdown = () => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(PRODUCT_ACTIONS.productsDropdownRequest());

    return api
      .fetchAPI(PRODUCT_DROPDOWN_API)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            PRODUCT_ACTIONS.productsDropdownRequestSuccess(response?.data?.data)
          );
          return response;
        } else {
          dispatch(
            PRODUCT_ACTIONS.productsDropdownRequestFailure("SERVER ERROR")
          );
        }
      })
      .catch((error) => {
        dispatch(
          PRODUCT_ACTIONS.productsDropdownRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
