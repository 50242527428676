import {
  EMAIL_UPSERT_API,
  EMAIL_FETCH_API,
  EMAILS_EXPORT_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as EMAIL_ACTIONS from "./actions";

export const fetchEmailList = (formData) => {
  const api = new APICore();

  const getApi = () => {
    return api.storeAPI(EMAIL_FETCH_API, formData);
  };

  return (dispatch) => {
    dispatch(EMAIL_ACTIONS.emailFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            EMAIL_ACTIONS.emailFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          EMAIL_ACTIONS.emailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertEmail = (formData, action = "delete") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "delete": {
        return api.deleteAPI(`${EMAIL_UPSERT_API}/${formData}`);
      }

      default:
        return;
    }
  };

  return (dispatch) => {
    dispatch(EMAIL_ACTIONS.emailUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            EMAIL_ACTIONS.emailUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          EMAIL_ACTIONS.emailUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const exportEmails = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(EMAIL_ACTIONS.exportEmailsRequest());

    return api
      .storeAPI(EMAILS_EXPORT_API, formData, "blob")
      .then((response) => {
        if (response?.status === 200) {
          dispatch(EMAIL_ACTIONS.exportEmailsRequestSuccess(response?.data));
          return response;
        } else {
          dispatch(EMAIL_ACTIONS.exportEmailsRequestFailure("SERVER ERROR"));
        }
      })
      .catch((error) => {
        dispatch(
          EMAIL_ACTIONS.exportEmailsRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
