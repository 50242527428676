import * as RESPONSE_ACTION_TYPES from "./types";

export const responsesFetchRequest = () => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSES_LIST_REQUEST,
});

export const responsesFetchRequestSuccess = (data) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSES_LIST_REQUEST_SUCCESS,
  data,
});

export const responsesFetchRequestFailure = (error) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSES_LIST_REQUEST_FAILURE,
  error,
});

export const responsesListCleanRequest = () => ({
  type: RESPONSE_ACTION_TYPES.RESPONSES_LIST_CLEAN_PROPS,
});

export const responsesDetailFetchRequest = () => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DETAIL_REQUEST,
});

export const responsesDetailFetchRequestSuccess = (data) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DETAIL_REQUEST_SUCCESS,
  data,
});

export const responsesDetailFetchRequestFailure = (error) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DETAIL_REQUEST_FAILURE,
  error,
});

export const responsesDetailCleanRequest = () => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_DETAIL_CLEAN_PROPS,
});

export const responseUpsertRequest = () => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_UPSERT_REQUEST,
});

export const responseUpsertRequestSuccess = (data) => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_UPSERT_REQUEST_SUCCESS,
  data,
});

export const responseUpsertRequestFailure = (error) => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_UPSERT_REQUEST_FAILURE,
  error,
});

export const responseUpsertCleanRequest = () => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_UPSERT_CLEAN_PROPS,
});

export const responseDropdownRequest = () => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DROPDOWN_REQUEST,
});

export const responseDropdownRequestSuccess = (data) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DROPDOWN_REQUEST_SUCCESS,
  data,
});

export const responseDropdownRequestFailure = (error) => ({
  type: RESPONSE_ACTION_TYPES.FETCH_RESPONSE_DROPDOWN_REQUEST_FAILURE,
  error,
});

export const responseDropdownCleanRequest = () => ({
  type: RESPONSE_ACTION_TYPES.RESPONSE_DROPDOWN_CLEAN_PROPS,
});
