import {
  DEALER_ADD_API,
  DEALER_DETAIL_FETCH_API,
  DEALER_DROPDOWN_API,
  DEALER_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as DEALER_ACTIONS from "./actions";

export const fetchDealersLists = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(DEALER_ACTIONS.dealersFetchRequest());

    return api
      .storeAPI(DEALER_FETCH_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            DEALER_ACTIONS.dealersFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          DEALER_ACTIONS.dealersFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchDealerDetailByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(DEALER_ACTIONS.dealersDetailFetchRequest());

    return api
      .fetchAPI(`${DEALER_DETAIL_FETCH_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            DEALER_ACTIONS.dealersDetailFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          DEALER_ACTIONS.dealersDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertDealer = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${DEALER_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${DEALER_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${DEALER_ADD_API}/${formData}`);
    }
  };

  return (dispatch) => {
    dispatch(DEALER_ACTIONS.dealerUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            DEALER_ACTIONS.dealerUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          DEALER_ACTIONS.dealerUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchDealerDropdown = () => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(DEALER_ACTIONS.dealerDropdownRequest());

    return api
      .fetchAPI(DEALER_DROPDOWN_API)
      .then((response) => {
        if (response?.status === 200) {
          let newResponse = [];
          if (response.data.data) {
            newResponse = response.data.data.map((item) => JSON.parse(item));

            dispatch(DEALER_ACTIONS.dealerDropdownRequestSuccess(newResponse));

            return newResponse;
          }

          return response;
        }
      })
      .catch((error) => {
        dispatch(
          DEALER_ACTIONS.dealerDropdownRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
