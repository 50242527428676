export const FETCH_DEALERS_LIST_REQUEST = "FETCH_DEALERS_LIST_REQUEST";
export const FETCH_DEALERS_LIST_REQUEST_SUCCESS =
  "FETCH_DEALERS_LIST_REQUEST_SUCCESS";
export const FETCH_DEALERS_LIST_REQUEST_FAILURE =
  "FETCH_DEALERS_LIST_REQUEST_FAILURE";

export const DEALERS_LIST_CLEAN_PROPS = "DEALERS_LIST_CLEAN_PROPS";

export const FETCH_DEALER_DETAIL_REQUEST = "FETCH_DEALER_DETAIL_REQUEST";
export const FETCH_DEALER_DETAIL_REQUEST_SUCCESS =
  "FETCH_DEALER_DETAIL_REQUEST_SUCCESS";
export const FETCH_DEALER_DETAIL_REQUEST_FAILURE =
  "FETCH_DEALER_DETAIL_REQUEST_FAILURE";

export const DEALER_DETAIL_CLEAN_PROPS = "DEALER_DETAIL_CLEAN_PROPS";

export const DEALER_UPSERT_REQUEST = "DEALER_UPSERT_REQUEST";
export const DEALER_UPSERT_REQUEST_SUCCESS = "DEALER_UPSERT_REQUEST_SUCCESS";
export const DEALER_UPSERT_REQUEST_FAILURE = "DEALER_UPSERT_REQUEST_FAILURE";

export const DEALER_UPSERT_CLEAN_PROPS = "DEALER_UPSERT_CLEAN_PROPS";

export const FETCH_DEALER_DROPDOWN_REQUEST = "FETCH_DEALER_DROPDOWN_REQUEST";
export const FETCH_DEALER_DROPDOWN_REQUEST_SUCCESS =
  "FETCH_DEALER_DROPDOWN_REQUEST_SUCCESS";
export const FETCH_DEALER_DROPDOWN_REQUEST_FAILURE =
  "FETCH_DEALER_DROPDOWN_REQUEST_FAILURE";

export const DEALER_DROPDOWN_CLEAN_PROPS = "DEALER_DROPDOWN_CLEAN_PROPS";
