import {
  STATION_ADD_API,
  STATION_DETAIL_FETCH_API,
  STATION_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as STATION_ACTIONS from "./actions";

export const fetchStationsLists = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(STATION_ACTIONS.stationsFetchRequest());

    return api
      .storeAPI(STATION_FETCH_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            STATION_ACTIONS.stationsFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          STATION_ACTIONS.stationsFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchStationDetailByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(STATION_ACTIONS.stationsDetailFetchRequest());

    return api
      .fetchAPI(`${STATION_DETAIL_FETCH_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            STATION_ACTIONS.stationsDetailFetchRequestSuccess(
              response?.data?.data
            )
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          STATION_ACTIONS.stationsDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertStation = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${STATION_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${STATION_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${STATION_ADD_API}/${formData}`);
    }
  };

  return (dispatch) => {
    dispatch(STATION_ACTIONS.stationUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            STATION_ACTIONS.stationUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          STATION_ACTIONS.stationUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
