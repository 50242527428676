import axios from "axios";

import { appConfig } from "../constants";
import { showToastError } from "./commonUtil";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = appConfig.API_URL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    switch (error.response.status) {
      case 401:
        message = "Invalid credentials";
        showToastError(message);
        break;
      case 403:
        message = "Access Forbidden";
        showToastError(message);
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        showToastError(message);
        break;
      default: {
        if (error.response?.data?.message) {
          showToastError(error.response?.data?.message);
        } else {
          showToastError("Something went wrong, Please try again");
        }
      }
    }
    return Promise.reject(message);
  }
  // }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  if (token)
    axios.defaults.headers.common[
      appConfig.AUTH_HEADER_KEY
    ] = `Bearer ${token}`;
  else delete axios.defaults.headers.common[appConfig.AUTH_HEADER_KEY];
};

const getDataFromLocalStorage = () => {
  const authLocalStorageObject = localStorage.getItem(appConfig.AUTH_LOCAL_KEY);
  return authLocalStorageObject
    ? typeof authLocalStorageObject == "object"
      ? authLocalStorageObject
      : JSON.parse(authLocalStorageObject)
    : null;
};

class APICore {
  constructor() {
    this.defaultErrorObject = { message: "Something went wrong" };
  }

  /**
   * Fetches data from given url
   */
  fetchAPI = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  /**
   * post given data to url
   */
  storeAPI = (url, data, type = null) => {
    if (type) {
      return axios.post(url, data, { responseType: type });
    }
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  updateAPI = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  deleteAPI = (url) => {
    return axios.delete(url);
  };

  isUserAuthenticated = () => {
    const loggedData = this.getLoggedInData();

    if (!loggedData) {
      return false;
    }
    return true;
    // const decoded = jwtDecode(loggedData.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   console.warn("access token expired");
    //   return false;
    // } else {
    //   return true;
    // }
  };

  setLoggedInUser = (data) => {
    if (data)
      localStorage.setItem(appConfig.AUTH_LOCAL_KEY, JSON.stringify(data));
    else {
      localStorage.removeItem(appConfig.AUTH_LOCAL_KEY);
    }
  };

  /**
   * Returns the logged in user
   */
  getLoggedInData = () => {
    return getDataFromLocalStorage();
  };

  setDataInLocalStorage = (modifiedUser) => {
    let localData = localStorage.getItem(appConfig.AUTH_LOCAL_KEY);
    if (localData) {
      const { token, user } = JSON.parse(localData);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let localData = getDataFromLocalStorage();
if (localData) {
  const { token } = localData;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
