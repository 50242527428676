export const FETCH_STATIONS_LIST_REQUEST = "FETCH_STATIONS_LIST_REQUEST";
export const FETCH_STATIONS_LIST_REQUEST_SUCCESS =
  "FETCH_STATIONS_LIST_REQUEST_SUCCESS";
export const FETCH_STATIONS_LIST_REQUEST_FAILURE =
  "FETCH_STATIONS_LIST_REQUEST_FAILURE";

export const STATIONS_LIST_CLEAN_PROPS = "STATIONS_LIST_CLEAN_PROPS";

export const FETCH_STATION_DETAIL_REQUEST = "FETCH_STATION_DETAIL_REQUEST";
export const FETCH_STATION_DETAIL_REQUEST_SUCCESS =
  "FETCH_STATION_DETAIL_REQUEST_SUCCESS";
export const FETCH_STATION_DETAIL_REQUEST_FAILURE =
  "FETCH_STATION_DETAIL_REQUEST_FAILURE";

export const STATION_DETAIL_CLEAN_PROPS = "STATION_DETAIL_CLEAN_PROPS";

export const STATION_UPSERT_REQUEST = "STATION_UPSERT_REQUEST";
export const STATION_UPSERT_REQUEST_SUCCESS = "STATION_UPSERT_REQUEST_SUCCESS";
export const STATION_UPSERT_REQUEST_FAILURE = "STATION_UPSERT_REQUEST_FAILURE";

export const STATION_UPSERT_CLEAN_PROPS = "STATION_UPSERT_CLEAN_PROPS";
