import * as DEALER_ACTION_TYPES from "./types";

export const dealersFetchRequest = () => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST,
});

export const dealersFetchRequestSuccess = (data) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST_SUCCESS,
  data,
});

export const dealersFetchRequestFailure = (error) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST_FAILURE,
  error,
});

export const dealersListCleanRequest = () => ({
  type: DEALER_ACTION_TYPES.DEALERS_LIST_CLEAN_PROPS,
});

export const dealersDetailFetchRequest = () => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST,
});

export const dealersDetailFetchRequestSuccess = (data) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST_SUCCESS,
  data,
});

export const dealersDetailFetchRequestFailure = (error) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST_FAILURE,
  error,
});

export const dealersDetailCleanRequest = () => ({
  type: DEALER_ACTION_TYPES.DEALER_DETAIL_CLEAN_PROPS,
});

export const dealerUpsertRequest = () => ({
  type: DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST,
});

export const dealerUpsertRequestSuccess = (data) => ({
  type: DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST_SUCCESS,
  data,
});

export const dealerUpsertRequestFailure = (error) => ({
  type: DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST_FAILURE,
  error,
});

export const dealerUpsertCleanRequest = () => ({
  type: DEALER_ACTION_TYPES.DEALER_UPSERT_CLEAN_PROPS,
});

export const dealerDropdownRequest = () => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST,
});

export const dealerDropdownRequestSuccess = (data) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST_SUCCESS,
  data,
});

export const dealerDropdownRequestFailure = (error) => ({
  type: DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST_FAILURE,
  error,
});

export const dealerDropdownCleanRequest = () => ({
  type: DEALER_ACTION_TYPES.DEALER_DROPDOWN_CLEAN_PROPS,
});
