export const FETCH_PRODUCTS_LIST_REQUEST = "FETCH_PRODUCTS_LIST_REQUEST";
export const FETCH_PRODUCTS_LIST_REQUEST_SUCCESS =
  "FETCH_PRODUCTS_LIST_REQUEST_SUCCESS";
export const FETCH_PRODUCTS_LIST_REQUEST_FAILURE =
  "FETCH_PRODUCTS_LIST_REQUEST_FAILURE";

export const PRODUCT_LIST_CLEAN_PROPS = "PRODUCT_LIST_CLEAN_PROPS";

export const FETCH_PRODUCTS_DETAIL_REQUEST = "FETCH_PRODUCTS_DETAIL_REQUEST";
export const FETCH_PRODUCTS_DETAIL_REQUEST_SUCCESS =
  "FETCH_PRODUCTS_DETAIL_REQUEST_SUCCESS";
export const FETCH_PRODUCTS_DETAIL_REQUEST_FAILURE =
  "FETCH_PRODUCTS_DETAIL_REQUEST_FAILURE";

export const FETCH_PRODUCTS_IMAGES_REQUEST = "FETCH_PRODUCTS_IMAGES_REQUEST";
export const FETCH_PRODUCTS_IMAGES_REQUEST_SUCCESS =
  "FETCH_PRODUCTS_IMAGES_REQUEST_SUCCESS";
export const FETCH_PRODUCTS_IMAGES_REQUEST_FAILURE =
  "FETCH_PRODUCTS_IMAGES_REQUEST_FAILURE";

export const FETCH_PRODUCT_FEATURES_REQUEST = "FETCH_PRODUCT_FEATURES_REQUEST";
export const FETCH_PRODUCT_FEATURES_REQUEST_SUCCESS =
  "FETCH_PRODUCT_FEATURES_REQUEST_SUCCESS";
export const FETCH_PRODUCT_FEATURES_REQUEST_FAILURE =
  "FETCH_PRODUCT_FEATURES_REQUEST_FAILURE";

export const FETCH_PRODUCT_VARIANT_REQUEST = "FETCH_PRODUCT_VARIANT_REQUEST";
export const FETCH_PRODUCT_VARIANT_REQUEST_SUCCESS =
  "FETCH_PRODUCT_VARIANT_REQUEST_SUCCESS";
export const FETCH_PRODUCT_VARIANT_REQUEST_FAILURE =
  "FETCH_PRODUCT_VARIANT_REQUEST_FAILURE";

export const PRODUCT_DETAIL_CLEAN_PROPS = "PRODUCT_DETAIL_CLEAN_PROPS";

export const PRODUCTS_UPSERT_REQUEST = "PRODUCTS_UPSERT_REQUEST";
export const PRODUCTS_UPSERT_REQUEST_SUCCESS =
  "PRODUCTS_UPSERT_REQUEST_SUCCESS";
export const PRODUCTS_UPSERT_REQUEST_FAILURE =
  "PRODUCTS_UPSERT_REQUEST_FAILURE";

export const PRODUCT_UPSERT_CLEAN_PROPS = "PRODUCT_UPSERT_CLEAN_PROPS";
export const PRODUCT_FEATURE_CLEAN_PROPS = "PRODUCT_FEATURE_CLEAN_PROPS";
export const PRODUCT_VARIANT_CLEAN_PROPS = "PRODUCT_VARIANT_CLEAN_PROPS";
export const PRODUCT_IMAGES_CLEAN_PROPS = "PRODUCT_IMAGES_CLEAN_PROPS";

export const FETCH_PRODUCTS_DROPDOWN_REQUEST =
  "FETCH_PRODUCTS_DROPDOWN_REQUEST";
export const FETCH_PRODUCTS_DROPDOWN_REQUEST_SUCCESS =
  "FETCH_PRODUCTS_DROPDOWN_REQUEST_SUCCESS";
export const FETCH_PRODUCTS_DROPDOWN_REQUEST_FAILURE =
  "FETCH_PRODUCTS_DROPDOWN_REQUEST_FAILURE";

export const PRODUCT_DROPDOWN_CLEAN_PROPS = "PRODUCT_DROPDOWN_CLEAN_PROPS";
