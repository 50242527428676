import * as STATION_ACTION_TYPES from "./types";

export const stationsFetchRequest = () => ({
  type: STATION_ACTION_TYPES.FETCH_STATIONS_LIST_REQUEST,
});

export const stationsFetchRequestSuccess = (data) => ({
  type: STATION_ACTION_TYPES.FETCH_STATIONS_LIST_REQUEST_SUCCESS,
  data,
});

export const stationsFetchRequestFailure = (error) => ({
  type: STATION_ACTION_TYPES.FETCH_STATIONS_LIST_REQUEST_FAILURE,
  error,
});

export const stationsListCleanRequest = () => ({
  type: STATION_ACTION_TYPES.STATIONS_LIST_CLEAN_PROPS,
});

export const stationsDetailFetchRequest = () => ({
  type: STATION_ACTION_TYPES.FETCH_STATION_DETAIL_REQUEST,
});

export const stationsDetailFetchRequestSuccess = (data) => ({
  type: STATION_ACTION_TYPES.FETCH_STATION_DETAIL_REQUEST_SUCCESS,
  data,
});

export const stationsDetailFetchRequestFailure = (error) => ({
  type: STATION_ACTION_TYPES.FETCH_STATION_DETAIL_REQUEST_FAILURE,
  error,
});

export const stationsDetailCleanRequest = () => ({
  type: STATION_ACTION_TYPES.STATION_DETAIL_CLEAN_PROPS,
});

export const stationUpsertRequest = () => ({
  type: STATION_ACTION_TYPES.STATION_UPSERT_REQUEST,
});

export const stationUpsertRequestSuccess = (data) => ({
  type: STATION_ACTION_TYPES.STATION_UPSERT_REQUEST_SUCCESS,
  data,
});

export const stationUpsertRequestFailure = (error) => ({
  type: STATION_ACTION_TYPES.STATION_UPSERT_REQUEST_FAILURE,
  error,
});

export const stationUpsertCleanRequest = () => ({
  type: STATION_ACTION_TYPES.STATION_UPSERT_CLEAN_PROPS,
});
