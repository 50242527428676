export const FETCH_EMAIL_LIST_REQUEST = "FETCH_EMAIL_LIST_REQUEST";
export const FETCH_EMAIL_LIST_REQUEST_SUCCESS =
  "FETCH_EMAIL_LIST_REQUEST_SUCCESS";
export const FETCH_EMAIL_LIST_REQUEST_FAILURE =
  "FETCH_EMAIL_LIST_REQUEST_FAILURE";

export const EMAIL_LIST_CLEAN_PROPS = "EMAIL_LIST_CLEAN_PROPS";

export const EMAIL_UPSERT_REQUEST = "EMAIL_UPSERT_REQUEST";
export const EMAIL_UPSERT_REQUEST_SUCCESS = "EMAIL_UPSERT_REQUEST_SUCCESS";
export const EMAIL_UPSERT_REQUEST_FAILURE = "EMAIL_UPSERT_REQUEST_FAILURE";

export const EMAIL_UPSERT_CLEAN_PROPS = "EMAIL_UPSERT_CLEAN_PROPS";

export const EXPORT_EMAIL_REQUEST = "EXPORT_EMAIL_REQUEST";
export const EXPORT_EMAIL_REQUEST_SUCCESS = "EXPORT_EMAIL_REQUEST_SUCCESS";
export const EXPORT_EMAIL_REQUEST_FAILURE = "EXPORT_EMAIL_REQUEST_FAILURE";

export const CLEAN_EXPORT_EMAIL_PROPS = "CLEAN_EXPORT_EMAIL_PROPS";
