export const FETCH_RESPONSES_LIST_REQUEST = "FETCH_RESPONSES_LIST_REQUEST";
export const FETCH_RESPONSES_LIST_REQUEST_SUCCESS =
  "FETCH_RESPONSES_LIST_REQUEST_SUCCESS";
export const FETCH_RESPONSES_LIST_REQUEST_FAILURE =
  "FETCH_RESPONSES_LIST_REQUEST_FAILURE";

export const RESPONSES_LIST_CLEAN_PROPS = "RESPONSES_LIST_CLEAN_PROPS";

export const FETCH_RESPONSE_DETAIL_REQUEST = "FETCH_RESPONSE_DETAIL_REQUEST";
export const FETCH_RESPONSE_DETAIL_REQUEST_SUCCESS =
  "FETCH_RESPONSE_DETAIL_REQUEST_SUCCESS";
export const FETCH_RESPONSE_DETAIL_REQUEST_FAILURE =
  "FETCH_RESPONSE_DETAIL_REQUEST_FAILURE";

export const RESPONSE_DETAIL_CLEAN_PROPS = "RESPONSE_DETAIL_CLEAN_PROPS";

export const RESPONSE_UPSERT_REQUEST = "RESPONSE_UPSERT_REQUEST";
export const RESPONSE_UPSERT_REQUEST_SUCCESS =
  "RESPONSE_UPSERT_REQUEST_SUCCESS";
export const RESPONSE_UPSERT_REQUEST_FAILURE =
  "RESPONSE_UPSERT_REQUEST_FAILURE";

export const RESPONSE_UPSERT_CLEAN_PROPS = "RESPONSE_UPSERT_CLEAN_PROPS";

export const FETCH_RESPONSE_DROPDOWN_REQUEST =
  "FETCH_RESPONSE_DROPDOWN_REQUEST";
export const FETCH_RESPONSE_DROPDOWN_REQUEST_SUCCESS =
  "FETCH_RESPONSE_DROPDOWN_REQUEST_SUCCESS";
export const FETCH_RESPONSE_DROPDOWN_REQUEST_FAILURE =
  "FETCH_RESPONSE_DROPDOWN_REQUEST_FAILURE";

export const RESPONSE_DROPDOWN_CLEAN_PROPS = "RESPONSE_DROPDOWN_CLEAN_PROPS";
