import React, { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./index.scss";

import { CustomLabel, MiniLoader, history } from "../paths";
import LOGO from "../../assets/images/logo-red.png";
import { userLogin } from "../../duck/services";
import { resetLoginRequest } from "../../duck/actions";

const Login = () => {
  const dispatch = useDispatch();

  const { authPayload, userLoggedIn, authLoading, authError } = useSelector(
    (state) => ({
      authPayload: state.auth.payload,
      authLoading: state.auth.loading,
      authError: state.auth.error,
      userLoggedIn: state.auth.userLoggedIn,
    })
  );

  const authFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Username required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: (values) => {
      dispatch(userLogin(values)).then((response) => {
        if (response?.status === 200) {
          history.push("/dashboard");
        }
      });
    },
  });

  useEffect(() => {
    dispatch(resetLoginRequest());
  }, []);

  return (
    <div className="login">
      <div className="login__content">
        <div className="login__content-body">
          <div className="logo-container">
            <img src={LOGO} className="cimex-logo" />
          </div>
          <h3>Welcome back</h3>
          <p>Please enter your login details to proceed.</p>
          <form className="login-form" autoComplete="off">
            <FormGroup className="custom-form-group">
              <CustomLabel label="Email" />
              <TextField
                className="m-0"
                variant="outlined"
                size="small"
                placeholder="Enter email address"
                value={authFormik?.values.email}
                onChange={authFormik.handleChange}
                id="email"
                name="email"
                disabled={authLoading}
              />
            </FormGroup>
            <FormGroup className="custom-form-group">
              <CustomLabel label="Password" />
              <TextField
                className="m-0"
                variant="outlined"
                size="small"
                placeholder="Enter password"
                type={"password"}
                value={authFormik?.values.password}
                onChange={authFormik.handleChange}
                id="password"
                name="password"
                disabled={authLoading}
              />
            </FormGroup>
            {/* <FormGroup className="custom-form-group">
              <Link className="forgot-password-link">Forgot Password</Link>
            </FormGroup> */}
            <FormGroup className="custom-form-group">
              <Button
                className="login-button btn-block"
                variant="contained"
                onClick={(e) => authFormik.handleSubmit(e)}
              >
                {authLoading ? <MiniLoader /> : "Sign In"}
              </Button>
            </FormGroup>
          </form>
          <p className="footer">
            <span>© {moment().format("YYYY")}</span> Cimex Nepal Pvt. Ltd.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
