import React from "react";
import { ChevronDown, LogOut, Settings } from "react-feather";
import { useDispatch } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { userLogout } from "../../duck/services";
import { APICore } from "../../utils";

const Header = () => {
  const dispatch = useDispatch();

  const api = new APICore();

  const adminData = api.getLoggedInData();

  return (
    <div className="header">
      <div className="header__custom-topbar">
        <div className="userprofile-toggler">
          <UncontrolledDropdown>
            <p>Welcome, {adminData?.name}</p>
            <DropdownToggle className="custom__toggler">
              <ChevronDown />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <Settings /> Settings
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(userLogout())}>
                <LogOut /> Sign Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
