import React from "react";
import { Route, Redirect } from "react-router-dom";
import { APICore } from "../utils";

const RestrictRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const api = new APICore();
  return (
    <Route
      {...rest}
      render={(props) =>
        api.isUserAuthenticated() ? (
          <Redirect to={"/dashboard"} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default RestrictRoute;
