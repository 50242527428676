import React, { Fragment } from "react";
import { useFormik } from "formik";

import "./index.scss";

const SearchBox = (props) => {
  const { searchFunction } = props;

  const formik = useFormik({
    initialValues: { searchItem: "" },
    onSubmit: (values) => {
      searchFunction(values);
    },
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <Fragment>
      <div className="input-group search-box">
        <input
          type="text"
          className="form-control search-input"
          placeholder="Search..."
          onChange={(e) => {
            formik.handleChange(e);
            if (e.target.value === "") {
              searchFunction("");
            }
          }}
          name="searchItem"
          id="searchItem"
          onKeyDown={handleKeyPress}
        />
      </div>
    </Fragment>
  );
};

export default SearchBox;
