export const FETCH_BOOKINGS_LIST_REQUEST = "FETCH_BOOKINGS_LIST_REQUEST";
export const FETCH_BOOKINGS_LIST_REQUEST_SUCCESS =
  "FETCH_BOOKINGS_LIST_REQUEST_SUCCESS";
export const FETCH_BOOKINGS_LIST_REQUEST_FAILURE =
  "FETCH_BOOKINGS_LIST_REQUEST_FAILURE";

export const BOOKINGS_LIST_CLEAN_PROPS = "BOOKINGS_LIST_CLEAN_PROPS";

export const FETCH_BOOKING_DETAIL_REQUEST = "FETCH_BOOKING_DETAIL_REQUEST";
export const FETCH_BOOKING_DETAIL_REQUEST_SUCCESS =
  "FETCH_BOOKING_DETAIL_REQUEST_SUCCESS";
export const FETCH_BOOKING_DETAIL_REQUEST_FAILURE =
  "FETCH_BOOKING_DETAIL_REQUEST_FAILURE";

export const BOOKING_DETAIL_CLEAN_PROPS = "BOOKING_DETAIL_CLEAN_PROPS";

export const BOOKING_UPSERT_REQUEST = "BOOKING_UPSERT_REQUEST";
export const BOOKING_UPSERT_REQUEST_SUCCESS = "BOOKING_UPSERT_REQUEST_SUCCESS";
export const BOOKING_UPSERT_REQUEST_FAILURE = "BOOKING_UPSERT_REQUEST_FAILURE";

export const BOOKING_UPSERT_CLEAN_PROPS = "BOOKING_UPSERT_CLEAN_PROPS";

export const EXPORT_BOOKING_REQUEST = "EXPORT_BOOKING_REQUEST";
export const EXPORT_BOOKING_REQUEST_SUCCESS = "EXPORT_BOOKING_REQUEST_SUCCESS";
export const EXPORT_BOOKING_REQUEST_FAILURE = "EXPORT_BOOKING_REQUEST_FAILURE";

export const CLEAN_EXPORT_BOOKING_PROPS = "CLEAN_EXPORT_BOOKING_PROPS";
