export const FETCH_BLOGS_LIST_REQUEST = "FETCH_BLOGS_LIST_REQUEST";
export const FETCH_BLOGS_LIST_REQUEST_SUCCESS =
  "FETCH_BLOGS_LIST_REQUEST_SUCCESS";
export const FETCH_BLOGS_LIST_REQUEST_FAILURE =
  "FETCH_BLOGS_LIST_REQUEST_FAILURE";

export const PRODUCT_LIST_CLEAN_PROPS = "PRODUCT_LIST_CLEAN_PROPS";

export const FETCH_BLOGS_DETAIL_REQUEST = "FETCH_BLOGS_DETAIL_REQUEST";
export const FETCH_BLOGS_DETAIL_REQUEST_SUCCESS =
  "FETCH_BLOGS_DETAIL_REQUEST_SUCCESS";
export const FETCH_BLOGS_DETAIL_REQUEST_FAILURE =
  "FETCH_BLOGS_DETAIL_REQUEST_FAILURE";

export const PRODUCT_DETAIL_CLEAN_PROPS = "PRODUCT_DETAIL_CLEAN_PROPS";

export const BLOGS_UPSERT_REQUEST = "BLOGS_UPSERT_REQUEST";
export const BLOGS_UPSERT_REQUEST_SUCCESS = "BLOGS_UPSERT_REQUEST_SUCCESS";
export const BLOGS_UPSERT_REQUEST_FAILURE = "BLOGS_UPSERT_REQUEST_FAILURE";

export const PRODUCT_UPSERT_CLEAN_PROPS = "PRODUCT_UPSERT_CLEAN_PROPS";
