import * as AUTH_ACTION_TYPES from "./types";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const authReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case AUTH_ACTION_TYPES.USER_LOGIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case AUTH_ACTION_TYPES.USER_LOGIN_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        userLoggedIn: true,
        errors: {},
      });

    case AUTH_ACTION_TYPES.USER_LOGIN_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
        userLoggedIn: false,
      });

    case AUTH_ACTION_TYPES.RESET_LOGIN_REQUEST:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
      });

    case AUTH_ACTION_TYPES.USER_LOGOUT_REQUEST: {
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        userLoggedIn: false,
        errors: {},
      });
    }

    default:
      return state;
  }
};

export default authReducer;
