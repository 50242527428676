import * as AUTH_ACTION_TYPES from "./types";

export const userLoginRequest = () => ({
  type: AUTH_ACTION_TYPES.USER_LOGIN_REQUEST,
});

export const userLoginRequestSuccess = (data) => ({
  type: AUTH_ACTION_TYPES.USER_LOGIN_REQUEST_SUCCESS,
  data,
});

export const userLoginRequestFailure = (error) => ({
  type: AUTH_ACTION_TYPES.USER_LOGIN_REQUEST_FAILURE,
  error,
});

export const resetLoginRequest = () => ({
  type: AUTH_ACTION_TYPES.RESET_LOGIN_REQUEST,
});

export const userLogoutRequest = () => ({
  type: AUTH_ACTION_TYPES.USER_LOGOUT_REQUEST,
});
