import { Edit, Eye } from "react-feather";
import React from "react";
import { Add, Delete } from "@material-ui/icons";
import classNames from "classnames";
export const EditIcon = ({ onClick }) => {
  return (
    <div className="custom-icon" onClick={onClick}>
      <Edit />
    </div>
  );
};

export const ViewIcon = ({ onClick }) => {
  return (
    <div className="custom-icon" onClick={onClick}>
      <Eye />
    </div>
  );
};

export const DeleteIcon = ({ onClick, customClassname }) => {
  return (
    <div
      className={classNames("custom-icon danger", customClassname)}
      onClick={onClick}
    >
      <Delete />
    </div>
  );
};

export const AddIcon = ({ onClick, customClassname }) => {
  return (
    <div
      className={classNames("custom-icon primary", customClassname)}
      onClick={onClick}
    >
      <Add />
    </div>
  );
};
