import * as BOOKING_ACTION_TYPES from "./types";

export const bookingsFetchRequest = () => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKINGS_LIST_REQUEST,
});

export const bookingsFetchRequestSuccess = (data) => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKINGS_LIST_REQUEST_SUCCESS,
  data,
});

export const bookingsFetchRequestFailure = (error) => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKINGS_LIST_REQUEST_FAILURE,
  error,
});

export const bookingsListCleanRequest = () => ({
  type: BOOKING_ACTION_TYPES.BOOKINGS_LIST_CLEAN_PROPS,
});

export const bookingsDetailFetchRequest = () => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKING_DETAIL_REQUEST,
});

export const bookingsDetailFetchRequestSuccess = (data) => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKING_DETAIL_REQUEST_SUCCESS,
  data,
});

export const bookingsDetailFetchRequestFailure = (error) => ({
  type: BOOKING_ACTION_TYPES.FETCH_BOOKING_DETAIL_REQUEST_FAILURE,
  error,
});

export const bookingsDetailCleanRequest = () => ({
  type: BOOKING_ACTION_TYPES.BOOKING_DETAIL_CLEAN_PROPS,
});

export const bookingUpsertRequest = () => ({
  type: BOOKING_ACTION_TYPES.BOOKING_UPSERT_REQUEST,
});

export const bookingUpsertRequestSuccess = (data) => ({
  type: BOOKING_ACTION_TYPES.BOOKING_UPSERT_REQUEST_SUCCESS,
  data,
});

export const bookingUpsertRequestFailure = (error) => ({
  type: BOOKING_ACTION_TYPES.BOOKING_UPSERT_REQUEST_FAILURE,
  error,
});

export const bookingUpsertCleanRequest = () => ({
  type: BOOKING_ACTION_TYPES.BOOKING_UPSERT_CLEAN_PROPS,
});

export const exportBookingDataRequest = () => ({
  type: BOOKING_ACTION_TYPES.EXPORT_BOOKING_REQUEST,
});

export const exportBookingDataRequestSuccess = (data) => ({
  type: BOOKING_ACTION_TYPES.EXPORT_BOOKING_REQUEST_SUCCESS,
  data,
});

export const exportBookingDataRequestFailure = (error) => ({
  type: BOOKING_ACTION_TYPES.EXPORT_BOOKING_REQUEST_FAILURE,
  error,
});

export const cleanExportProps = () => ({
  type: BOOKING_ACTION_TYPES.CLEAN_EXPORT_BOOKING_PROPS,
});
