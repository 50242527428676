import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";

import "./index.scss";

const AdminLayout = (props) => {
  const { children } = props;
  return (
    <div className="vertical-layout">
      <Header />
      <SideBar />
      <div className="vertical-layout__content">
        <div className="content">{children}</div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default AdminLayout;
