import * as PRODUCT_ACTION_TYPES from "./types";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
  pagination: {},
  singlePayload: [],
  singleLoading: false,
  singleErrors: {},
  upsertPayload: [],
  upsertLoading: false,
  upsertErrors: {},
  imagesPayload: [],
  imagesErrors: {},
  imagesLoading: false,
  features: [],
  featuresLoading: false,
  featuresErrors: {},
  variantsLoading: false,
  variants: [],
  variantsErrors: {},
  productDropdown: [],
  productDropdownErrors: {},
  productDropdownLoading: false,
};

const productReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        pagination: {
          current: action.data.current_page,
          pageSize: action.data.per_page,
          total: action.data.total,
          totalPage:
            Math.round(action.data.total / action.data.per_page) +
            (Math.abs(action.data.total / action.data.per_page) >
            Math.round(action.data.total / action.data.per_page)
              ? 1
              : 0),
          showSizeChanger: true,
        },
        errors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_LIST_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_LIST_CLEAN_PROPS:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: {},
        pagination: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST:
      return Object.assign({}, state, {
        singleLoading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        singleLoading: false,
        singlePayload: action.data,
        singleErrors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DETAIL_REQUEST_FAILURE:
      return Object.assign({}, state, {
        singleLoading: false,
        singleErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST:
      return Object.assign({}, state, {
        imagesLoading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        imagesLoading: false,
        imagesPayload: action.data,
        imagesErrors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_IMAGES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        imagesLoading: false,
        imagesErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_DETAIL_CLEAN_PROPS:
      return Object.assign({}, state, {
        singlePayload: [],
        singleLoading: false,
        singleErrors: {},
      });

    case PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST:
      return Object.assign({}, state, {
        upsertLoading: true,
      });

    case PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        upsertLoading: false,
        upsertPayload: action.data,
        upsertErrors: {},
      });

    case PRODUCT_ACTION_TYPES.PRODUCTS_UPSERT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        upsertLoading: false,
        upsertErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST:
      return Object.assign({}, state, {
        featuresLoading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        featuresLoading: false,
        features: action.data,
        featuresErrors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_FEATURES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        featuresLoading: false,
        featuresErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST:
      return Object.assign({}, state, {
        variantsLoading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        variantsLoading: false,
        variants: action.data,
        variantsErrors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT_VARIANT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        variantsLoading: false,
        variantsErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_UPSERT_CLEAN_PROPS:
      return Object.assign({}, state, {
        upsertPayload: [],
        upsertLoading: false,
        upsertErrors: {},
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_FEATURE_CLEAN_PROPS:
      return Object.assign({}, state, {
        features: [],
        featuresLoading: false,
        featuresErrors: {},
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_VARIANT_CLEAN_PROPS:
      return Object.assign({}, state, {
        variantsLoading: false,
        variants: [],
        variantsErrors: {},
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_IMAGES_CLEAN_PROPS:
      return Object.assign({}, state, {
        imagesPayload: [],
        imagesErrors: {},
        imagesLoading: false,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST:
      return Object.assign({}, state, {
        productDropdownLoading: true,
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        productDropdownLoading: false,
        productDropdown: action.data,
        productDropdownErrors: {},
      });

    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_DROPDOWN_REQUEST_FAILURE:
      return Object.assign({}, state, {
        productDropdownLoading: false,
        productDropdownErrors: action.error,
      });

    case PRODUCT_ACTION_TYPES.PRODUCT_DROPDOWN_CLEAN_PROPS:
      return Object.assign({}, state, {
        productDropdown: [],
        productDropdownLoading: false,
        productDropdownErrors: {},
      });

    default:
      return state;
  }
};

export default productReducer;
