import React from "react";

const CustomLabel = (props) => {
  const { label, isRequired } = props;
  return (
    <label htmlFor={label} className="mb-1 custom-label">
      {label} {isRequired && <span style={{ color: "red" }}>*</span>}
    </label>
  );
};

export default CustomLabel;
