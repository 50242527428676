export const EDITOR_LONG_OPTION = {
  options: [
    "blockType",
    "inline",
    "fontSize",
    "link",
    "list",
    "textAlign",
    "image",
  ],
  inline: {
    options: ["bold", "italic", "underline"],
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
  },
};
