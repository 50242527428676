import React from "react";
import { Input } from "reactstrap";

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-4">
    Showing {from} to {to} of {size} Results
  </span>
);

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => {
  return (
    <React.Fragment>
      <label className="d-inline mr-1">Show</label>
      <Input
        type="select"
        name="select"
        id="no-entries"
        className="custom-select custom-select-sm d-inline col-2"
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(e.target.value)}
      >
        {options.map((option, idx) => {
          return (
            <option key={option.page} value={option.page}>
              {option.text}
            </option>
          );
        })}
      </Input>
      <label className="d-inline ml-1">entries</label>
    </React.Fragment>
  );
};

export const paginationObject = (current, pageSize, total) => {
  return {
    page: current,
    sizePerPage: pageSize,
    totalSize: total,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageRenderer,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
    ],
  };
};
