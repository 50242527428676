export const FETCH_EXCHANGE_LIST_REQUEST = "FETCH_EXCHANGE_LIST_REQUEST";
export const FETCH_EXCHANGE_LIST_REQUEST_SUCCESS =
  "FETCH_EXCHANGE_LIST_REQUEST_SUCCESS";
export const FETCH_EXCHANGE_LIST_REQUEST_FAILURE =
  "FETCH_EXCHANGE_LIST_REQUEST_FAILURE";

export const EXCHANGE_LIST_CLEAN_PROPS = "EXCHANGE_LIST_CLEAN_PROPS";

export const FETCH_EXCHANGE_DETAIL_REQUEST = "FETCH_EXCHANGE_DETAIL_REQUEST";
export const FETCH_EXCHANGE_DETAIL_REQUEST_SUCCESS =
  "FETCH_EXCHANGE_DETAIL_REQUEST_SUCCESS";
export const FETCH_EXCHANGE_DETAIL_REQUEST_FAILURE =
  "FETCH_EXCHANGE_DETAIL_REQUEST_FAILURE";

export const EXCHANGE_DETAIL_CLEAN_PROPS = "EXCHANGE_DETAIL_CLEAN_PROPS";

export const EXCHANGE_UPSERT_REQUEST = "EXCHANGE_UPSERT_REQUEST";
export const EXCHANGE_UPSERT_REQUEST_SUCCESS =
  "EXCHANGE_UPSERT_REQUEST_SUCCESS";
export const EXCHANGE_UPSERT_REQUEST_FAILURE =
  "EXCHANGE_UPSERT_REQUEST_FAILURE";

export const EXCHANGE_UPSERT_CLEAN_PROPS = "EXCHANGE_UPSERT_CLEAN_PROPS";
