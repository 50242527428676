import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "./assets/scss/index.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";

import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { history } from "./utils";
import configureStore from "./redux/configureStore";
import { Notification } from "./components";

const { PUBLIC_URL } = process.env;
const store = configureStore({}, history);

ReactDOM.render(
  <Suspense fallback={<div>Loading ...</div>}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router basname={PUBLIC_URL} history={history}>
          <Notification />
          <App />
        </Router>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
