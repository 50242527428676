import {
  BLOG_ADD_API,
  BLOG_DETAIL_FETCH_API,
  BLOG_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as BLOG_ACTIONS from "./actions";

export const fetchBlogLists = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(BLOG_ACTIONS.blogsFetchRequest());

    return api
      .storeAPI(BLOG_FETCH_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(BLOG_ACTIONS.blogsFetchRequestSuccess(response?.data?.data));
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BLOG_ACTIONS.blogsFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchBlogDetailByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(BLOG_ACTIONS.blogsDetailFetchRequest());

    return api
      .fetchAPI(`${BLOG_DETAIL_FETCH_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            BLOG_ACTIONS.blogsDetailFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BLOG_ACTIONS.blogsDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const uploadBlogImage = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    return api
      .storeAPI(`${BLOG_ADD_API}/storeimage`, formData)
      .then((response) => {
        if (response?.status === 200) {
          return response;
        }
      })
      .catch((error) => {
        if (error?.response) {
          return error?.response?.data;
        } else {
          return "SERVER ERROR";
        }
      });
  };
};

export const upsertBlog = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${BLOG_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${BLOG_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${BLOG_ADD_API}/${formData}`);
    }
  };
  return (dispatch) => {
    dispatch(BLOG_ACTIONS.blogUpsertFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            BLOG_ACTIONS.blogUpsertFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BLOG_ACTIONS.blogUpsertFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
