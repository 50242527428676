import * as DEALER_ACTION_TYPES from "./types";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
  pagination: {},
  singlePayload: [],
  singleLoading: false,
  singleErrors: {},
  upsertPayload: [],
  upsertLoading: false,
  upsertErrors: {},
  dropdown: [],
  dropdownLoading: false,
  dropdownErrors: {},
};

const dealerReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        pagination: {
          current: action.data.current_page,
          pageSize: action.data.per_page,
          total: action.data.total,
          totalPage:
            Math.round(action.data.total / action.data.per_page) +
            (Math.abs(action.data.total / action.data.per_page) >
            Math.round(action.data.total / action.data.per_page)
              ? 1
              : 0),
          showSizeChanger: true,
        },
        errors: {},
      });

    case DEALER_ACTION_TYPES.FETCH_DEALERS_LIST_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case DEALER_ACTION_TYPES.DEALERS_LIST_CLEAN_PROPS:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: {},
        pagination: {},
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST:
      return Object.assign({}, state, {
        singleLoading: true,
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        singleLoading: false,
        singlePayload: action.data,
        singleErrors: {},
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DETAIL_REQUEST_FAILURE:
      return Object.assign({}, state, {
        singleLoading: false,
        singleErrors: action.error,
      });

    case DEALER_ACTION_TYPES.DEALER_DETAIL_CLEAN_PROPS:
      return Object.assign({}, state, {
        singlePayload: [],
        singleLoading: false,
        singleErrors: {},
      });

    case DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST:
      return Object.assign({}, state, {
        upsertLoading: true,
      });

    case DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        upsertLoading: false,
        upsertPayload: action.data,
        upsertErrors: {},
      });

    case DEALER_ACTION_TYPES.DEALER_UPSERT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        upsertLoading: false,
        upsertErrors: action.error,
      });

    case DEALER_ACTION_TYPES.DEALER_UPSERT_CLEAN_PROPS:
      return Object.assign({}, state, {
        upsertPayload: [],
        upsertLoading: false,
        upsertErrors: {},
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST:
      return Object.assign({}, state, {
        dropdownLoading: true,
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        dropdownLoading: false,
        dropdown: action.data,
        dropdownErrors: {},
      });

    case DEALER_ACTION_TYPES.FETCH_DEALER_DROPDOWN_REQUEST_FAILURE:
      return Object.assign({}, state, {
        dropdownLoading: false,
        dropdown: action.error,
      });

    case DEALER_ACTION_TYPES.DEALER_DROPDOWN_CLEAN_PROPS:
      return Object.assign({}, state, {
        dropdown: [],
        dropdownLoading: false,
        dropdownErrors: {},
      });

    default:
      return state;
  }
};

export default dealerReducer;
