import * as EXCHANGE_ACTION_TYPES from "./types";

export const exchangeFetchRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_LIST_REQUEST,
});

export const exchangeFetchRequestSuccess = (data) => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_LIST_REQUEST_SUCCESS,
  data,
});

export const exchangeFetchRequestFailure = (error) => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_LIST_REQUEST_FAILURE,
  error,
});

export const exchangeListCleanRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_LIST_CLEAN_PROPS,
});

export const exchangeDetailFetchRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_DETAIL_REQUEST,
});

export const exchangeDetailFetchRequestSuccess = (data) => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_DETAIL_REQUEST_SUCCESS,
  data,
});

export const exchangeDetailFetchRequestFailure = (error) => ({
  type: EXCHANGE_ACTION_TYPES.FETCH_EXCHANGE_DETAIL_REQUEST_FAILURE,
  error,
});

export const exchangeDetailCleanRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_DETAIL_CLEAN_PROPS,
});

export const exchangeUpsertRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_UPSERT_REQUEST,
});

export const exchangeUpsertRequestSuccess = (data) => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_UPSERT_REQUEST_SUCCESS,
  data,
});

export const exchangeUpsertRequestFailure = (error) => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_UPSERT_REQUEST_FAILURE,
  error,
});

export const exchangeUpsertCleanRequest = () => ({
  type: EXCHANGE_ACTION_TYPES.EXCHANGE_UPSERT_CLEAN_PROPS,
});
