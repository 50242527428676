import { APICore, setAuthorization, history } from "../../utils";
import * as AUTH_ACTIONS from "./actions";

export const userLogin = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(AUTH_ACTIONS.userLoginRequest());

    return api
      .storeAPI("/admin/login", formData)
      .then((response) => {
        if (response?.status === 200) {
          api.setLoggedInUser(response.data.data);
          setAuthorization(response.data.data.token);
          dispatch(AUTH_ACTIONS.userLoginRequestSuccess(response?.data?.data));
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          AUTH_ACTIONS.userLoginRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const userLogout = () => {
  const api = new APICore();

  return (dispatch) => {
    api.setLoggedInUser(null);
    setAuthorization(null);

    dispatch(AUTH_ACTIONS.userLogoutRequest());
    history.push("/login");
  };
};
