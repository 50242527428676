import * as BLOG_ACTION_TYPES from "./types";

export const blogsFetchRequest = () => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_LIST_REQUEST,
});

export const blogsFetchRequestSuccess = (data) => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_LIST_REQUEST_SUCCESS,
  data,
});

export const blogsFetchRequestFailure = (error) => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_LIST_REQUEST_FAILURE,
  error,
});

export const blogsListCleanRequest = () => ({
  type: BLOG_ACTION_TYPES.PRODUCT_LIST_CLEAN_PROPS,
});

export const blogsDetailFetchRequest = () => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_DETAIL_REQUEST,
});

export const blogsDetailFetchRequestSuccess = (data) => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_DETAIL_REQUEST_SUCCESS,
  data,
});

export const blogsDetailFetchRequestFailure = (error) => ({
  type: BLOG_ACTION_TYPES.FETCH_BLOGS_DETAIL_REQUEST_FAILURE,
  error,
});

export const blogsDetailCleanRequest = () => ({
  type: BLOG_ACTION_TYPES.PRODUCT_DETAIL_CLEAN_PROPS,
});

export const blogUpsertFetchRequest = () => ({
  type: BLOG_ACTION_TYPES.BLOGS_UPSERT_REQUEST,
});

export const blogUpsertFetchRequestSuccess = (data) => ({
  type: BLOG_ACTION_TYPES.BLOGS_UPSERT_REQUEST_SUCCESS,
  data,
});

export const blogUpsertFetchRequestFailure = (error) => ({
  type: BLOG_ACTION_TYPES.BLOGS_UPSERT_REQUEST_FAILURE,
  error,
});

export const blogUpsertCleanRequest = () => ({
  type: BLOG_ACTION_TYPES.PRODUCT_UPSERT_CLEAN_PROPS,
});
