import React from "react";
import classNames from "classnames";

import "./index.scss";

const MiniLoader = (props) => {
  const { message = "" } = props;
  return (
    <div className="spinner-border" role="status">
      <span className="sr-only">{message}</span>
    </div>
  );
};

export const Loader = ({ message }) => {
  return (
    <div className="spinner-div text-center w-100 mt-5 mb-5">
      <div
        className="spinner-border"
        style={{ color: "#6666ff" }}
        role="status"
      >
        <span className="sr-only"></span>
      </div>
      <br />
      {message && <div className={"text-muted"}>{message}</div>}
    </div>
  );
};

export const OverlayLoader = ({
  message,
  active,
  children,
  className,
  stickLoader = false,
}) => {
  if (children) {
    return (
      <div
        className={classNames(
          { "loading-overlay": active, "no-loading-overlay": !active },
          className
        )}
      >
        <>{children}</>
        {active && (
          <div
            className={classNames("spinner-div text-center w-100", {
              backgroundTransparent: stickLoader,
            })}
          >
            <div className="spinner-inner-container">
              {message && <div className={"text-message mr-2"}>{message}</div>}

              {stickLoader ? (
                <div className="loader"></div>
              ) : (
                <div
                  className="spinner-border"
                  style={{ color: "#3699FF" }}
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}

              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default MiniLoader;
