import { PASSWORD_UPDATE_API } from "../../constants";
import { APICore } from "../../utils";
import * as SETTING_ACTIONS from "./actions";

export const updatePassword = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(SETTING_ACTIONS.passwordChangeRequest());

    return api
      .updateAPI(PASSWORD_UPDATE_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            SETTING_ACTIONS.passwordChangeRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          SETTING_ACTIONS.passwordChangeRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
