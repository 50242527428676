import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactCrop from "react-image-crop";

import "./index.scss";
import { getImageCustomName } from "../../utils";

const Cropper = (props) => {
  const {
    modelProps: { imgUploadModal, toggleImgViewModal },
    getCropProps,
    getUploadModalHeader,
    getUploadModalMessage,
    handleUploadModalConfirm,
  } = props;

  const [imageFile, setImageFile] = useState(null);
  const [fileBlobUrl, setFileBlobUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9, width: 550 });

  const handleConfirm = () => {
    getCroppedImg();
    handleUploadModalConfirm(resultFile);
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
    setFileBlobUrl(URL.createObjectURL(e.target.files[0]));
  };

  const getCroppedImg = () => {
    if (!crop.width || !crop.height) {
      setResult(null);
      return;
    }
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            setResult(null);
            resolve();
          }

          const base64Image = canvas.toDataURL("image/webp");
          setResult(base64Image);

          const newImage = new File(
            [blob],
            getImageCustomName(imageFile?.name),
            { type: "image/webp" }
          );
          setResultFile(newImage);
          resolve(blob);
        },
        "image/webp",
        1
      );
    });
  };

  useEffect(() => {
    setFileBlobUrl(null);
    setCrop(null);
    setImage(null);
    setResult(null);
    setResultFile(null);

    setCrop(getCropProps());
    if (imgUploadModal) {
      setTimeout(() => {
        let button = document.getElementById("upload-img-button");
        button.click();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgUploadModal]);

  return (
    <Modal
      className="image-upload-modal"
      isOpen={imgUploadModal}
      toggle={toggleImgViewModal}
    >
      <ModalHeader toggle={toggleImgViewModal}>
        {getUploadModalHeader()}
      </ModalHeader>
      <ModalBody className={"pb-0"}>
        {fileBlobUrl ? (
          <ReactCrop
            src={fileBlobUrl}
            onImageLoaded={setImage}
            crop={crop}
            onChange={(e) => {
              setResult(null);
              setCrop(e);
            }}
          />
        ) : (
          <div
            className={"upload-modal-message-box"}
            onClick={() => {
              let button = document.getElementById("upload-img-button");
              button.click();
            }}
          >
            <div className="text-center message-upload-box">
              <i className="h1 text-muted uil-cloud-upload" />
              <p>{getUploadModalMessage()}</p>
            </div>
            <input
              id="upload-img-button"
              type={"file"}
              accept={"image/png, image/svg, image/jpeg, image/jpg"}
              onChange={handleFileChange}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={"text-right"}>
        {!result && (
          <Button className="btn-light" onClick={getCroppedImg}>
            Crop
          </Button>
        )}
        {result && (
          <Button className="bg-blue" onClick={handleConfirm}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default Cropper;
