import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { Login } from "./auth";
import { AdminLayout, AuthLayout } from "../layout";
import { PrivateRoute, RestrictRoute } from "../routes";
import { Loader } from "../components";

const Dashboard = React.lazy(() => import("./dashboard"));
const Products = React.lazy(() => import("./products"));
const Blogs = React.lazy(() => import("./blogs"));
const CharginStations = React.lazy(() => import("./charging-stations"));
const Bookings = React.lazy(() => import("./bookings"));
const VBookings = React.lazy(() => import("./vehicle-bookings"));
const Settings = React.lazy(() => import("./settings"));
const Dealers = React.lazy(() => import("./dealers"));
const Responses = React.lazy(() => import("./responses"));
const VehicleExchange = React.lazy(() => import("./vehicle-exchange"));
const Newsletter = React.lazy(() => import("./newletter"));

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <RestrictRoute
          exact
          path="/login"
          layout={AuthLayout}
          component={Login}
        />
        <PrivateRoute
          path={`/`}
          layout={AdminLayout}
          component={PrivateRoutes}
        />
      </Switch>
    </Suspense>
  );
}

const PrivateRoutes = ({ match }) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path={`${match.url}dashboard`} component={Dashboard} />
      <Route path={`${match.url}products`} component={Products} />
      <Route path={`${match.url}blogs`} component={Blogs} />
      <Route
        path={`${match.url}charging-stations`}
        component={CharginStations}
      />
      <Route path={`${match.url}dealers`} component={Dealers} />
      <Route path={`${match.url}bookings/test-drive`} component={Bookings} />
      <Route path={`${match.url}bookings/vehicles`} component={VBookings} />
      <Route path={`${match.url}responses`} component={Responses} />
      <Route path={`${match.url}exchange`} component={VehicleExchange} />
      <Route path={`${match.url}settings`} component={Settings} />
      <Route path={`${match.url}newsletter`} component={Newsletter} />
    </Switch>
  </Suspense>
);
