import * as SETTING_ACTION_TYPES from "./types";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const settingReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,

        errors: {},
      });

    case SETTING_ACTION_TYPES.PASSWORD_CHANGE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    default:
      return state;
  }
};

export default settingReducer;
