import * as EMAIL_ACTION_TYPES from "./types";

export const emailFetchRequest = () => ({
  type: EMAIL_ACTION_TYPES.FETCH_EMAIL_LIST_REQUEST,
});

export const emailFetchRequestSuccess = (data) => ({
  type: EMAIL_ACTION_TYPES.FETCH_EMAIL_LIST_REQUEST_SUCCESS,
  data,
});

export const emailFetchRequestFailure = (error) => ({
  type: EMAIL_ACTION_TYPES.FETCH_EMAIL_LIST_REQUEST_FAILURE,
  error,
});

export const emailListCleanRequest = () => ({
  type: EMAIL_ACTION_TYPES.EMAIL_LIST_CLEAN_PROPS,
});

export const emailUpsertRequest = () => ({
  type: EMAIL_ACTION_TYPES.EMAIL_UPSERT_REQUEST,
});

export const emailUpsertRequestSuccess = (data) => ({
  type: EMAIL_ACTION_TYPES.EMAIL_UPSERT_REQUEST_SUCCESS,
  data,
});

export const emailUpsertRequestFailure = (error) => ({
  type: EMAIL_ACTION_TYPES.EMAIL_UPSERT_REQUEST_FAILURE,
  error,
});

export const emailUpsertCleanRequest = () => ({
  type: EMAIL_ACTION_TYPES.EMAIL_UPSERT_CLEAN_PROPS,
});

export const exportEmailsRequest = () => ({
  type: EMAIL_ACTION_TYPES.EXPORT_EMAIL_REQUEST,
});

export const exportEmailsRequestSuccess = (data) => ({
  type: EMAIL_ACTION_TYPES.EXPORT_EMAIL_REQUEST_SUCCESS,
  data,
});

export const exportEmailsRequestFailure = (error) => ({
  type: EMAIL_ACTION_TYPES.EXPORT_EMAIL_REQUEST_FAILURE,
  error,
});

export const cleanEmailExportProps = () => ({
  type: EMAIL_ACTION_TYPES.CLEAN_EXPORT_EMAIL_PROPS,
});
