import * as DASHBOARD_ACTION_TYPES from "./types";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const dashboardReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_STATS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_STATS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_STATS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case DASHBOARD_ACTION_TYPES.DASHBOARD_STATS_CLEAN_PROPS:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: {},
      });


    default:
      return state;
  }
};

export default dashboardReducer;
